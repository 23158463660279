<script>
import appConfig from "@/app.config";
import {Modal} from "bootstrap";
import CurrencyInput from "@/components/CurrencyInput";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {CurrencyInput},
  data() {
    return {
      // global data
      branch_id: null,
      disposed_asset: {},
      add_modal:null,
      dispose_modal:null,
      remaining_value:0,
      branches: [],
      branch_sites: [],
      newAsset: {},
      registerAsset: {},
      // end global data
      fixedAssets: [],
      typesOptions: [],
      cashAccounts: [],
      addAsset: [{}],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchBudget: null,
      isDepreciable: false,
      isRegisterDepreciable: false,
      classes: [],
      subclasses: [],
      SearchMode:false,
      search:"",
      // appprices:[]
    };
  },
  computed: {
    totalDisposalAccountsValue() {
      return this.addAsset
          .filter(account=>account.cash_account && account.value)
          .reduce((sum,account)=>sum+account.value,0)
    },
  },
  methods: {
    searchFixedAssets() {
      this.SearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("budget/search", { search: this.search })
        .then((res) => {
          this.fixedAssets = res.data;
        });
    },
    cancelFixedAssetsSearchMode() {
      this.search = "";
      this.SearchMode = false;
      this.getFixedAssetsPagination(this.page);
    },
    registerFixedAsset() {
      console.log(this.registerAsset);
      this.typesOptions.forEach((type) => {
        if (type.id == this.registerAsset.fixed_asset_type_id) {
          this.registerAsset.type_name = type.name_en;
        }
      });
      this.http
        .post("transactions/register-assets", { register: this.registerAsset })
        .then(() => {
          this.registerAsset = {};
          this.getFixedAssetsPagination(this.Page);
        });
    },
    addFixedAsset() {
      this.typesOptions.forEach((type) => {
        if (type.id == this.newAsset.fixed_asset_type_id) {
          this.newAsset.type_name = type.name_en;
        }
      });
      console.log("this.newAsset", this.newAsset,"this.addAsset",this.addAsset);
      this.http
        .post("transactions/fixed-assets", {
          newAsset: this.newAsset,
          accounts: this.addAsset,
        })
        .then(() => {
          this.newAsset = {};
          this.addAsset = [{}];
          this.getFixedAssetsPagination(this.Page);
          this.add_modal.hide()
        });
    },
    preEditFixedAsset(fixedAsset) {
      this.newAsset = JSON.parse(JSON.stringify(fixedAsset));
      this.newAsset.depreciable = this.newAsset.depreciable ? true : false;
      this.getFixedAssetSubClasses(fixedAsset.fixed_asset_class_id);
    },
    preAddAsset() {
      this.getAccounts()
      this.add_modal.show()
    },
    editFixedAsset() {
      this.http
        .put("fixed-assets", this.newAsset.id, this.newAsset)
        .then(() => {
          this.getFixedAssetsPagination(this.Page);
        });
    },
    deleteFixedAsset(fixedAsset) {
      var data = {
        title: "popups.delete",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          this.http.delete("fixed-assets", fixedAsset.id).then(() => {
            this.getFixedAssetsPagination(this.Page);
          });
        }
      });
    },
    getFixedAssetsPagination(page) {
      this.http
        .post("fixed-assets/pagination", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          this.Page = page;
          this.TotalPages = Math.ceil(res.tot / this.limit);
          console.log(this.TotalPages);
          this.fixedAssets = res.data;
          console.log("this.fixedAssets", this.fixedAssets);
        });
    },
    getAccounts() {
      this.http.post("transactions/cash-accounts", {}).then((resp) => {
        this.cashAccounts = resp.data;
      });
    },
    doDispose(){

      this.http.post("fixed-assets/disposal", {
        id : this.disposed_asset.id,
        remaining_value:this.remaining_value,
        fixed_asset_type_id : this.disposed_asset.fixed_asset_type_id,
        accounts : this.addAsset
      }).then(() => {
        this.dispose_modal.hide()
        this.getFixedAssetsPagination(this.Page);
      });
    },
    transferAsset(asset) {
      let locale = this.$i18n.locale;
      let self = this;
      this.http
        .post("branches/execlude", { asse_brancht_id: asset.branch_id })
        .then((res) => {
          this.branches = res.data;
          var data = {
            title: "fixed_assets.transfer",
            inputs: [
              {
                model: "to_branch_id",
                type: "select",
                options: (() => {
                  return this.branches.map((data) => {
                    return {
                      value: data.id,
                      label: locale == "ar" ? data.name : data.name_en,
                    };
                  });
                })(),
                label: "fixed_assets.transfer_to",
              },
            ],
            buttons: [
              {
                text: "fixed_assets.transfer",
                closer: true,
                color: "primary",
                handler: (obj) => {
                  obj.from_branch_id = asset.branch_id;
                  this.http
                    .put("fixed-assets/transfer", asset.id, obj)
                    .then((res) => {
                      self.branches = res.data;
                      self.getFixedAssetsPagination(self.page);
                    });
                },
              },
              {
                text: "Cancel",
                closer: true,
                color: "danger",
                handler: () => {
                  console.log("Like Clicked");
                },
              },
            ],
          };
          this.popup.modal(data);
        });
    },
    getFixedAssetTypesOptions() {
      this.http.get("fixed-assets/types").then((resp) => {
        this.typesOptions = resp.data;
      });
    },
    sellFixedAsset(asset) {
      this.disposed_asset = asset
      this.http
        .post("fixed-assets/process-disposal", { id: asset.id })
        .then((res) => {
          console.log(res.status);
          this.remaining_value = res.data[0]?.remaining_value
          this.addAsset = [{}]
          this.getAccounts()
          this.dispose_modal.show()
        });
    },
    getBranchSites() {
      this.http
        .post("level-one-chart-of-accounts/get-branch-sites", {})
        .then((resp) => {
          console.log(resp.data);
          this.branch_sites = resp.data;
        });
    },
    getFixedAssetsClasses() {
      this.http.get("fixed-assets/classes").then((res) => {
        this.classes = res.data;
      });
    },
    getFixedAssetSubClasses(id) {
      this.http.get("fixed-assets/subclasses/" + id).then((res) => {
        this.subclasses = res.data;
      });
    },
    exportExcel() {
      this.http.download(
        "level-one-chart-of-accounts/assets/download",
        `Fixed Assets.xlsx`,
        {
          branch_id: this.branch_id,
        }
      );
    },
  },
  mounted() {
    const current_user = JSON.parse(localStorage.currentUser);
    this.branch_id = current_user.branch_id;
    this.getFixedAssetsPagination(1);
    this.getFixedAssetTypesOptions();
    this.getFixedAssetsClasses();
    this.getBranchSites();
    this.getAccounts();
    this.dispose_modal = new Modal("#disposeModal")
    this.add_modal = new Modal("#addModal")
  },
};
</script>

<template>
  <div :dir="$i18n.locale == 'ar' ? 'ltr' : 'ltr'">
    <div class="row">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <div class="search-box chat-search-box w-50">
                <div class="position-relative">
                  <input
                    @keyup="searchFixedAssets()"
                    type="text"
                    class="form-control fa-lg text-light"
                    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                    :placeholder="$t('popups.search')"
                    v-model="search"
                    style="
                      background-color: #2a3042 !important;
                      wdith: 250px !important;
                    "
                  />
                  <i class="bx bx-search-alt search-icon text-light"></i>
                </div>
              </div>
            </div>
            <div class="col-6" style="display: flex; justify-content: flex-end">
              <button
                class="btn-close text-light"
                v-if="SearchMode"
                @click="cancelFixedAssetsSearchMode"
              ></button>
              <button
                type="button"
                class="btn btn-success float-end mb-4 me-2"
                @click="exportExcel()"
              >
                {{ $t("fixed_assets.excel") }}
              </button>
              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#registerModal"
                class="btn btn-secondary float-end mb-4 me-2"
              >
                {{ $t("fixed_assets.register") }}
              </button>
              <button
                type="button"
                @click="preAddAsset()"
                class="btn btn-primary float-end mb-4"
              >
                {{ $t("fixed_assets.buy") }}
              </button>
            </div>
          </div>
          <div class="table-responsive">
            <table
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              class="table table-centered table-nowrap table-striped table-hover align-middle"
            >
              <thead>
                <tr
                  class="text-light"
                  style="background-color: #2a3042 !important"
                >
                  <th scope="col">#</th>
                  <th scope="col">{{ $t("fixed_assets.label") }}</th>
                  <th scope="col">{{ $t("fixed_assets.label_en") }}</th>
                  <th scope="col">{{ $t("fixed_assets.alt_name") }}</th>
                  <th scope="col">{{ $t("fixed_assets.alt_name_en") }}</th>
                  <th scope="col">{{ $t("fixed_assets.type") }}</th>
                  <th scope="col">{{ $t("fixed_assets.initial") }}</th>
                  <th scope="col">{{ $t("fixed_assets.depreciable") }}</th>
                  <th scope="col">{{ $t("fixed_assets.rate") }}</th>
                  <th scope="col">{{ $t("fixed_assets.branch_site") }}</th>
                  <th scope="col">{{ $t("fixed_assets.main_class") }}</th>
                  <th scope="col">{{ $t("fixed_assets.sub_class") }}</th>
                  <th scope="col">{{ $t("fixed_assets.under_warranty") }}</th>
                  <th scope="col">{{ $t("fixed_assets.warranty_exp") }}</th>
                  <th scope="col">{{ $t("fixed_assets.installation_date") }}</th>
                  <th scope="col">
                    {{ $t("fixed_assets.maintenance_rating") }}
                  </th>
                  <th scope="col">{{ $t("fixed_assets.rhh") }}</th>
                  <th scope="col">Pof</th>
                  <th scope="col">Cof</th>
                  <th scope="col">{{ $t("fixed_assets.r_f") }}</th>
                  <th scope="col">{{ $t("fixed_assets.risk_score") }}</th>
                  <th scope="col">{{ $t("fixed_assets.comment") }}</th>
                  <th scope="col">{{ $t("fixed_assets.created") }}</th>
                  <th scope="col">{{ $t("fixed_assets.updated") }}</th>
                  <th scope="col">{{ $t("fixed_assets.operations") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  :title="
                    $i18n.locale == 'ar'
                      ? fixedAsset?.name
                      : fixedAsset?.name_en
                  "
                  v-for="(fixedAsset, index) in fixedAssets"
                  :key="index"
                  class=""
                >
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{ fixedAsset?.name }}
                  </td>
                  <td>
                    {{ fixedAsset?.name_en }}
                  </td>
                  <td>
                    {{ fixedAsset?.alt_name }}
                  </td>
                  <td>
                    {{ fixedAsset?.alt_name_en }}
                  </td>
                  <td>
                    {{
                      $i18n.locale == "ar"
                        ? fixedAsset?.type
                        : fixedAsset?.type_en
                    }}
                  </td>
                  <td>{{ fixedAsset?.initial_book_value }}</td>
                  <td>
                    {{
                      fixedAsset?.depreciable
                        ? $t("fixed_assets.yes")
                        : $t("fixed_assets.no")
                    }}
                  </td>
                  <td>
                    {{
                      fixedAsset?.depreciation_rate
                        ? fixedAsset?.depreciation_rate
                        : "-"
                    }}
                  </td>
                  <td>
                    {{
                      $i18n.locale == "ar"
                        ? fixedAsset?.site_name
                        : fixedAsset?.site_name_en
                    }}
                  </td>
                  <td>
                    {{
                      $i18n.locale == "ar"
                        ? fixedAsset?.class_name
                        : fixedAsset?.class_name_en
                    }}
                  </td>
                  <td>
                    {{
                      $i18n.locale == "ar"
                        ? fixedAsset?.subclass_name
                        : fixedAsset?.subclass_name_en
                    }}
                  </td>
                  <td>
                    {{
                      fixedAsset?.under_warranty
                        ? $t("fixed_assets.yes")
                        : $t("fixed_assets.no")
                    }}
                  </td>
                  <td>{{ fixedAsset?.warranty_exp }}</td>
                  <td>{{ fixedAsset?.installation_date }}</td>
                  <td>{{ fixedAsset?.maintenance_rating }}</td>
                  <td>{{ fixedAsset?.rhh }}</td>
                  <td>{{ fixedAsset?.pof }}</td>
                  <td>{{ fixedAsset?.cof }}</td>
                  <td>{{ fixedAsset?.r_f }}</td>
                  <td>{{ fixedAsset?.risk_score }}</td>
                  <td>{{ fixedAsset?.comment }}</td>
                  <td>{{ fixedAsset?.created.split("T")[0] }}</td>
                  <td>{{ fixedAsset?.updated.split("T")[0] }}</td>
                  <td>
                    <router-link
                      style="margin-inline-end: 4px"
                      :to="'/fixed-assets/profile/' + fixedAsset?.id"
                    >
                      <button
                        class="btn btn-info"
                        role="button"
                        data-toggle="tooltip"
                        data-placement="top"
                        :title="
                          $i18n.locale == 'ar'
                            ? fixedAsset.name
                            : fixedAsset.name_en +
                              ' ' +
                              $t('fixed_assets.profile')
                        "
                      >
                        <i class="bx bxs-detail"></i>
                      </button>
                    </router-link>
                    <a
                      style="margin-inline-end: 4px"
                      class="btn btn-primary btn-md"
                      @click="transferAsset(fixedAsset)"
                      role="button"
                      >{{ $t("fixed_assets.transfer") }}</a
                    >
                    <a
                      style="margin-inline-end: 4px"
                      class="btn btn-primary btn-md"
                      @click="sellFixedAsset(fixedAsset)"
                      role="button"
                      >{{ $t("fixed_assets.disposal") }}</a
                    >
                    <a
                      style="margin-inline-end: 4px"
                      class="btn btn-primary btn-md"
                      @click="preEditFixedAsset(fixedAsset)"
                      data-bs-toggle="modal"
                      data-bs-target="#editModal"
                      role="button"
                      >{{ $t("popups.edit") }}</a
                    >
                    <a
                      class="btn btn-danger btn-md"
                      href="javascript: void(0);"
                      role="button"
                      @click="deleteFixedAsset(fixedAsset)"
                      >{{ $t("popups.delete") }}</a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- end  table -->

    <!--end-->
    <!--Start Add Modal-->
    <div
      class="modal fade"
      id="addModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="addFixedAsset()">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("popups.add") }}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style="margin: 0"
              ></button>
            </div>

            <div class="modal-body">
              <div class="row">
                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.branch_site")
                    }}</label>
                    <select
                      class="form-select"
                      v-model="newAsset.branch_site_id"
                    >
                      <option
                        v-for="site in branch_sites"
                        :key="site"
                        :value="site.id"
                      >
                        {{ $i18n.locale == "en" ? site.name_en : site.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <label for="asset_id">{{ $t("fixed_assets.type") }}</label>

                    <select
                      v-model="newAsset.fixed_asset_type_id"
                      class="form-select mb-2"
                      id="account_id"
                      :placeholder="$t('fixed_assets.type')"
                    >
                      <option
                        v-for="options in typesOptions"
                        :key="options"
                        :value="options?.id"
                      >
                        {{
                          $i18n.locale == "ar"
                            ? options?.name
                            : options?.name_en
                        }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.main_class")
                    }}</label>
                    <select
                      class="form-select"
                      @change="
                        getFixedAssetSubClasses(newAsset.fixed_asset_class_id)
                      "
                      v-model="newAsset.fixed_asset_class_id"
                    >
                      <option :value="cls.id" :key="cls" v-for="cls in classes">
                        {{ $i18n.locale == "en" ? cls.name_en : cls.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div
                  class="row d-flex align-items-center mb-2"
                  v-if="newAsset.fixed_asset_class_id"
                >
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.sub_class")
                    }}</label>
                    <select
                      class="form-select"
                      v-model="newAsset.asset_subclass_id"
                    >
                      <option
                        :value="cls.id"
                        :key="cls"
                        v-for="cls in subclasses"
                      >
                        {{ $i18n.locale == "en" ? cls.name_en : cls.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <label for="asset_id">{{ $t("fixed_assets.label") }}</label>
                    <input
                      v-model="newAsset.name"
                      type="text"
                      class="form-control mb-2"
                      :placeholder="$t('fixed_assets.label')"
                    />
                  </div>
                </div>
                <div class="row d-flex align-items-center">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.label_en")
                    }}</label>
                    <input
                      v-model="newAsset.name_en"
                      type="text"
                      class="form-control mb-2"
                      :placeholder="$t('fixed_assets.label_en')"
                    />
                  </div>
                </div>
                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.alt_name")
                    }}</label>
                    <input
                      v-model="newAsset.alt_name"
                      type="text"
                      class="form-control mb-2"
                      :placeholder="$t('fixed_assets.alt_name')"
                    />
                  </div>
                </div>
                <div class="row d-flex align-items-center">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.alt_name_en")
                    }}</label>
                    <input
                      v-model="newAsset.alt_name_en"
                      type="text"
                      class="form-control mb-2"
                      :placeholder="$t('fixed_assets.alt_name_en')"
                    />
                  </div>
                </div>

                <div class="row d-flex align-items-center">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.initial")
                    }}</label>
                    <currency-input
                      v-model="newAsset.initial_book_value"
                      class="form-control mb-2"
                      :placeholder="$t('fixed_assets.initial')"
                    />
                  </div>
                </div>
                <div class="row d-flex align-items-center">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.replacement_cost")
                    }}</label>
                    <currency-input
                      v-model="newAsset.replacement_cost"
                      class="form-control mb-2"
                      :placeholder="$t('fixed_assets.replacement_cost')"
                    />
                  </div>
                </div>
                <div class="row d-flex align-items-center">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.depreciable")
                    }}</label>
                    <select
                      required
                      class="form-select mb-2"
                      v-model="newAsset.depreciable"
                      id=""
                    >
                      <option :value="1">
                        {{ $t("fixed_assets.yes") }}
                      </option>
                      <option :value="0">
                        {{ $t("fixed_assets.no") }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row d-flex align-items-center" v-if="newAsset.depreciable">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.salvage_value")
                    }}</label>
                    <currency-input
                      v-model="newAsset.salvage_value"
                      class="form-control mb-2"
                      :placeholder="$t('fixed_assets.salvage_value')"
                    />
                  </div>
                </div>
                <div class="row d-flex align-items-center" v-if="newAsset.depreciable">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.useful_life_years")
                    }}</label>
                    <input
                      v-model="newAsset.useful_life_years"
                      type="number"
                      class="form-control mb-2"
                      :placeholder="$t('fixed_assets.useful_life_years')"
                    />
                  </div>
                </div>
                <div class="row d-flex align-items-center">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.installation_date")
                    }}</label>
                    <input
                      v-model="newAsset.installation_date"
                      type="date"
                      class="form-control mb-2"
                      :placeholder="$t('fixed_assets.installation_date')"
                    />
                  </div>
                </div>
                <div class="row d-flex align-items-center mb-2">
                  <div class="col form-check">
                    <input
                      type="checkbox"
                      v-model="newAsset.under_warranty"
                      id="under_warranty"
                      class="form-check-input"
                    />
                    <label class="form-check-label" for="under_warranty">{{
                      $t("fixed_assets.under_warranty")
                    }}</label>
                  </div>
                </div>
                <div
                  class="row d-flex align-items-center mb-2"
                  v-if="newAsset.under_warranty"
                >
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.warranty_exp")
                    }}</label>
                    <input
                      v-model="newAsset.warranty_exp"
                      type="date"
                      class="form-control mb-2"
                      :placeholder="$t('fixed_assets.warranty_exp')"
                    />
                  </div>
                </div>
                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.maintenance_rating")
                    }}</label>
                    <input
                      v-model="newAsset.maintenance_rating"
                      type="number"
                      class="form-control mb-2"
                      :placeholder="$t('fixed_assets.maintenance_rating')"
                    />
                  </div>
                </div>
                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <label for="asset_id">{{ $t("fixed_assets.rhh") }}</label>
                    <select class="form-select" v-model="newAsset.rhh">
                      <option value="R">
                        {{ $i18n.locale == "en" ? "Repaired" : "قابل للإصلاح" }}
                      </option>
                      <option value="RH">
                        {{
                          $i18n.locale == "en"
                            ? "Rehabilitated"
                            : "قابل لإعادة التأهيل"
                        }}
                      </option>
                      <option value="RHH">
                        {{
                          $i18n.locale == "en"
                            ? "Can be repaired or Rehabilitated"
                            : "قابل للإصلاح وإعادة التأهيل"
                        }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <label for="asset_id">Pof</label>
                    <select class="form-select" v-model="newAsset.pof">
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </div>
                </div>
                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <label for="asset_id">Cof</label>
                    <select class="form-select" v-model="newAsset.cof">
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </div>
                </div>
                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <label for="asset_id">{{ $t("fixed_assets.r_f") }}</label>
                    <input
                      v-model="newAsset.r_f"
                      min="0"
                      max="1"
                      step="0.01"
                      type="number"
                      class="form-control mb-2"
                      :placeholder="$t('fixed_assets.r_f')"
                    />
                  </div>
                </div>
                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.risk_score")
                    }}</label>
                    <input
                      v-model="newAsset.risk_score"
                      type="text"
                      class="form-control mb-2"
                      :placeholder="$t('fixed_assets.risk_score')"
                    />
                  </div>
                </div>
                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.comment")
                    }}</label>
                    <textarea
                      v-model="newAsset.comment"
                      class="form-control mb-2"
                      :placeholder="$t('fixed_assets.comment')"
                    ></textarea>
                  </div>
                </div>
                <div>
                  <div class="row d-flex align-items-center">
                    <div class="col">
                      <label for="asset_id">{{
                        $t("fixed_assets.choose_account")
                      }}</label>
                    </div>
                    <div class="col d-flex justify-content-end">
                      <button
                        v-if="addAsset.length < 3"
                        type="button"
                        @click="addAsset.push({})"
                        class="btn float-end"
                      >
                        <span
                          class="bx bxs-plus-circle float-end fa-2x"
                          style="color: #2a3042 !important"
                        ></span>
                      </button>
                    </div>
                  </div>
                  <div v-for="(asset, addAssetIndex) in addAsset" :key="asset">
                    <div class="row my-3">
                      <div class="d-flex justify-content-between">
                        <select
                          v-model="asset.cash_account"
                          class="form-select mb-2"
                          id="asset_id"
                          :placeholder="$t('fixed_assets.accounts')"
                        >
                          <option
                            v-for="cashAccount in cashAccounts"
                            :key="cashAccount"
                            :value="cashAccount"
                          >
                            {{
                              $i18n.locale == "ar"
                                ? cashAccount?.name
                                : cashAccount?.name_en
                            }} - ({{cashAccount.balance.toLocaleString()}})
                          </option>
                        </select>
                        <currency-input
                          v-model="asset.value" :max="asset?.cash_account?.balance"
                          class="form-control mb-2 mx-1"
                          :placeholder="$t('emps.amount')"
                        />
                        <span class="mx-2">
                          <button
                            class="btn btn-danger"
                            @click="addAsset.splice(addAssetIndex,1)"
                            v-if="addAsset.length > 1"
                          >
                            <i class="bx bx-trash"></i>
                          </button>
                          <button v-else disabled class="btn btn-danger">
                            <i class="bx bx-trash"></i>
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="modal-footer"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            >
              <button type="submit" class="btn btn-primary">
                {{ $t("popups.add") }}
              </button>
              <button
                data-bs-dismiss="modal"
                type="button"
                class="btn btn-danger"
              >
                {{ $t("popups.cancel") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--End Add Modal-->

    <!--Start register Modal-->
    <div
      class="modal fade"
      id="registerModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="registerFixedAsset()">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("fixed_assets.register") }}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style="margin: 0"
              ></button>
            </div>

            <div class="modal-body">
              <div class="row">
                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.branch_site")
                    }}</label>
                    <select
                      class="form-select"
                      v-model="newAsset.branch_site_id"
                    >
                      <option
                        v-for="site in branch_sites"
                        :key="site"
                        :value="site.id"
                      >
                        {{ $i18n.locale == "en" ? site.name_en : site.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <label for="asset_id">{{ $t("fixed_assets.type") }}</label>

                    <select
                      v-model="registerAsset.fixed_asset_type_id"
                      class="form-select mb-2"
                      id="account_id"
                      :placeholder="$t('fixed_assets.type')"
                    >
                      <option
                        v-for="options in typesOptions"
                        :key="options"
                        :value="options?.id"
                      >
                        {{
                          $i18n.locale == "ar"
                            ? options?.name
                            : options?.name_en
                        }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.main_class")
                    }}</label>
                    <select
                      class="form-select"
                      @change="
                        getFixedAssetSubClasses(
                          registerAsset.fixed_asset_class_id
                        )
                      "
                      v-model="registerAsset.fixed_asset_class_id"
                    >
                      <option :value="cls.id" :key="cls" v-for="cls in classes">
                        {{ $i18n.locale == "en" ? cls.name_en : cls.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div
                  class="row d-flex align-items-center mb-2"
                  v-if="registerAsset.fixed_asset_class_id"
                >
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.sub_class")
                    }}</label>
                    <select
                      class="form-select"
                      v-model="registerAsset.asset_subclass_id"
                    >
                      <option
                        :value="cls.id"
                        :key="cls"
                        v-for="cls in subclasses"
                      >
                        {{ $i18n.locale == "en" ? cls.name_en : cls.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <label for="asset_id">{{ $t("fixed_assets.label") }}</label>
                    <input
                      v-model="registerAsset.name"
                      type="text"
                      class="form-control mb-2"
                      :placeholder="$t('fixed_assets.label')"
                    />
                  </div>
                </div>
                <div class="row d-flex align-items-center">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.label_en")
                    }}</label>
                    <input
                      v-model="registerAsset.name_en"
                      type="text"
                      class="form-control mb-2"
                      :placeholder="$t('fixed_assets.label_en')"
                    />
                  </div>
                </div>
                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.alt_name")
                    }}</label>
                    <input
                      v-model="registerAsset.alt_name"
                      type="text"
                      class="form-control mb-2"
                      :placeholder="$t('fixed_assets.alt_name')"
                    />
                  </div>
                </div>
                <div class="row d-flex align-items-center">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.alt_name_en")
                    }}</label>
                    <input
                      v-model="registerAsset.alt_name_en"
                      type="text"
                      class="form-control mb-2"
                      :placeholder="$t('fixed_assets.alt_name_en')"
                    />
                  </div>
                </div>
                <div class="row d-flex align-items-center">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.initial")
                    }}</label>
                    <currency-input
                      v-model="registerAsset.initial_book_value"
                      class="form-control mb-2"
                      :placeholder="$t('fixed_assets.initial')"
                    />
                  </div>
                </div>
                <div class="row d-flex align-items-center">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.replacement_cost")
                    }}</label>
                    <currency-input
                      v-model="registerAsset.replacement_cost"
                      class="form-control mb-2"
                      :placeholder="$t('fixed_assets.replacement_cost')"
                    />
                  </div>
                </div>
                <div class="row d-flex align-items-center">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.depreciable")
                    }}</label>
                    <select
                      required
                      class="form-select mb-2"
                      v-model="registerAsset.depreciable"
                      id=""
                    >
                      <option :value="1">
                        {{ $t("fixed_assets.yes") }}
                      </option>
                      <option :value="0">
                        {{ $t("fixed_assets.no") }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row d-flex align-items-center" v-if="registerAsset.depreciable">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.salvage_value")
                    }}</label>
                    <currency-input
                      v-model="registerAsset.salvage_value"
                      class="form-control mb-2"
                      :placeholder="$t('fixed_assets.salvage_value')"
                    />
                  </div>
                </div>
                <div class="row d-flex align-items-center" v-if="registerAsset.depreciable">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.useful_life_years")
                    }}</label>
                    <input
                      v-model="registerAsset.useful_life_years"
                      type="number"
                      class="form-control mb-2"
                      :placeholder="$t('fixed_assets.useful_life_years')"
                    />
                  </div>
                </div>
                <div class="row d-flex align-items-center" v-if="registerAsset.depreciable">
                  <div class="col">
                    <label for="asset_id">{{
                        $t("fixed_assets.accumulated_depr")
                      }}</label>
                    <input
                        v-model="registerAsset.accumulated_depr"
                        type="number"
                        class="form-control mb-2"
                        :placeholder="$t('fixed_assets.accumulated_depr')"
                    />
                  </div>
                </div>
                <div class="row d-flex align-items-center">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.installation_date")
                    }}</label>
                    <input
                      v-model="registerAsset.installation_date"
                      type="date"
                      class="form-control mb-2"
                      :placeholder="$t('fixed_assets.installation_date')"
                    />
                  </div>
                </div>
                <div class="row d-flex align-items-center mb-2">
                  <div class="col form-check">
                    <input
                      type="checkbox"
                      v-model="registerAsset.under_warranty"
                      id="under_warranty"
                      class="form-check-input"
                    />
                    <label class="form-check-label" for="under_warranty">{{
                      $t("fixed_assets.under_warranty")
                    }}</label>
                  </div>
                </div>
                <div
                  class="row d-flex align-items-center mb-2"
                  v-if="registerAsset.under_warranty"
                >
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.warranty_exp")
                    }}</label>
                    <input
                      v-model="registerAsset.warranty_exp"
                      type="date"
                      class="form-control mb-2"
                      :placeholder="$t('fixed_assets.warranty_exp')"
                    />
                  </div>
                </div>
                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.maintenance_rating")
                    }}</label>
                    <input
                      v-model="registerAsset.maintenance_rating"
                      type="number"
                      class="form-control mb-2"
                      :placeholder="$t('fixed_assets.maintenance_rating')"
                    />
                  </div>
                </div>
                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <label for="asset_id">{{ $t("fixed_assets.rhh") }}</label>
                    <select class="form-select" v-model="registerAsset.rhh">
                      <option value="R">
                        {{ $i18n.locale == "en" ? "Repaired" : "قابل للإصلاح" }}
                      </option>
                      <option value="RH">
                        {{
                          $i18n.locale == "en"
                            ? "Rehabilitated"
                            : "قابل لإعادة التأهيل"
                        }}
                      </option>
                      <option value="RHH">
                        {{
                          $i18n.locale == "en"
                            ? "Can be repaired or Rehabilitated"
                            : "قابل للإصلاح وإعادة التأهيل"
                        }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <label for="asset_id">Pof</label>
                    <select class="form-select" v-model="registerAsset.pof">
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </div>
                </div>
                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <label for="asset_id">Cof</label>
                    <select class="form-select" v-model="registerAsset.cof">
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </div>
                </div>
                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <label for="asset_id">{{ $t("fixed_assets.r_f") }}</label>
                    <input
                      v-model="registerAsset.r_f"
                      min="0"
                      max="1"
                      step="0.01"
                      type="number"
                      class="form-control mb-2"
                      :placeholder="$t('fixed_assets.r_f')"
                    />
                  </div>
                </div>
                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.risk_score")
                    }}</label>
                    <input
                      v-model="registerAsset.risk_score"
                      type="text"
                      class="form-control mb-2"
                      :placeholder="$t('fixed_assets.risk_score')"
                    />
                  </div>
                </div>
                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.comment")
                    }}</label>
                    <textarea
                      v-model="registerAsset.comment"
                      class="form-control mb-2"
                      :placeholder="$t('fixed_assets.comment')"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="modal-footer"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            >
              <button type="submit" class="btn btn-primary">
                {{ $t("popups.add") }}
              </button>
              <button
                data-bs-dismiss="modal"
                type="button"
                class="btn btn-danger"
              >
                {{ $t("popups.cancel") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--End register Modal-->

    <!--Start Edit Modal-->
    <div
      class="modal fade"
      id="editModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="editFixedAsset()">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("popups.edit") }}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style="margin: 0"
              ></button>
            </div>

            <div class="modal-body">
              <div class="row">
                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.branch_site")
                    }}</label>
                    <select
                      class="form-select"
                      v-model="newAsset.branch_site_id"
                    >
                      <option
                        v-for="site in branch_sites"
                        :key="site"
                        :value="site.id"
                      >
                        {{ $i18n.locale == "en" ? site.name_en : site.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.main_class")
                    }}</label>
                    <select
                      class="form-select"
                      @change="
                        getFixedAssetSubClasses(newAsset.fixed_asset_class_id)
                      "
                      v-model="newAsset.fixed_asset_class_id"
                    >
                      <option :value="cls.id" :key="cls" v-for="cls in classes">
                        {{ $i18n.locale == "en" ? cls.name_en : cls.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div
                  class="row d-flex align-items-center mb-2"
                  v-if="newAsset.fixed_asset_class_id"
                >
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.sub_class")
                    }}</label>
                    <select
                      class="form-select"
                      v-model="newAsset.asset_subclass_id"
                    >
                      <option
                        :value="cls.id"
                        :key="cls"
                        v-for="cls in subclasses"
                      >
                        {{ $i18n.locale == "en" ? cls.name_en : cls.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <label for="asset_id">{{ $t("fixed_assets.label") }}</label>
                    <input
                      v-model="newAsset.name"
                      type="text"
                      class="form-control mb-2"
                      :placeholder="$t('fixed_assets.label')"
                    />
                  </div>
                </div>
                <div class="row d-flex align-items-center">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.label_en")
                    }}</label>
                    <input
                      v-model="newAsset.name_en"
                      type="text"
                      class="form-control mb-2"
                      :placeholder="$t('fixed_assets.label_en')"
                    />
                  </div>
                </div>
                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.alt_name")
                    }}</label>
                    <input
                      v-model="newAsset.alt_name"
                      type="text"
                      class="form-control mb-2"
                      :placeholder="$t('fixed_assets.alt_name')"
                    />
                  </div>
                </div>
                <div class="row d-flex align-items-center">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.alt_name_en")
                    }}</label>
                    <input
                      v-model="newAsset.alt_name_en"
                      type="text"
                      class="form-control mb-2"
                      :placeholder="$t('fixed_assets.alt_name_en')"
                    />
                  </div>
                </div>

                <div class="row d-flex align-items-center">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.initial")
                    }}</label>
                    <currency-input
                      v-model="newAsset.initial_book_value"
                      class="form-control mb-2"
                      :placeholder="$t('fixed_assets.initial')"
                    />
                  </div>
                </div>
                <div class="row d-flex align-items-center">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.replacement_cost")
                    }}</label>
                    <currency-input
                      v-model="newAsset.replacement_cost"
                      class="form-control mb-2"
                      :placeholder="$t('fixed_assets.replacement_cost')"
                    />
                  </div>
                </div>
                <div class="row d-flex align-items-center">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.depreciable")
                    }}</label>
                    <select
                      required
                      class="form-select mb-2"
                      v-model="newAsset.depreciable"
                      id=""
                    >
                      <option :value="1">
                        {{ $t("fixed_assets.yes") }}
                      </option>
                      <option :value="0">
                        {{ $t("fixed_assets.no") }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row d-flex align-items-center" v-if="newAsset.depreciable">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.salvage_value")
                    }}</label>
                    <input
                      v-model="newAsset.salvage_value"
                      type="text"
                      class="form-control mb-2"
                      :placeholder="$t('fixed_assets.salvage_value')"
                    />
                  </div>
                </div>
                <div class="row d-flex align-items-center" v-if="newAsset.depreciable">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.useful_life_years")
                    }}</label>
                    <input
                      v-model="newAsset.useful_life_years"
                      type="number"
                      class="form-control mb-2"
                      :placeholder="$t('fixed_assets.useful_life_years')"
                    />
                  </div>
                </div>
                <div class="row d-flex align-items-center">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.installation_date")
                    }}</label>
                    <input
                      v-model="newAsset.installation_date"
                      type="date"
                      class="form-control mb-2"
                      :placeholder="$t('fixed_assets.installation_date')"
                    />
                  </div>
                </div>
                <div class="row d-flex align-items-center mb-2">
                  <div class="col form-check">
                    <input
                      type="checkbox"
                      v-model="newAsset.under_warranty"
                      id="under_warranty"
                      class="form-check-input"
                    />
                    <label class="form-check-label" for="under_warranty">{{
                      $t("fixed_assets.under_warranty")
                    }}</label>
                  </div>
                </div>
                <div
                  class="row d-flex align-items-center mb-2"
                  v-if="newAsset.under_warranty"
                >
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.warranty_exp")
                    }}</label>
                    <input
                      v-model="newAsset.warranty_exp"
                      type="date"
                      class="form-control mb-2"
                      :placeholder="$t('fixed_assets.warranty_exp')"
                    />
                  </div>
                </div>
                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.maintenance_rating")
                    }}</label>
                    <input
                      v-model="newAsset.maintenance_rating"
                      type="number"
                      class="form-control mb-2"
                      :placeholder="$t('fixed_assets.maintenance_rating')"
                    />
                  </div>
                </div>
                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <label for="asset_id">{{ $t("fixed_assets.rhh") }}</label>
                    <select class="form-select" v-model="newAsset.rhh">
                      <option value="R">
                        {{ $i18n.locale == "en" ? "Repaired" : "قابل للإصلاح" }}
                      </option>
                      <option value="RH">
                        {{
                          $i18n.locale == "en"
                            ? "Rehabilitated"
                            : "قابل لإعادة التأهيل"
                        }}
                      </option>
                      <option value="RHH">
                        {{
                          $i18n.locale == "en"
                            ? "Can be repaired or Rehabilitated"
                            : "قابل للإصلاح وإعادة التأهيل"
                        }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <label for="asset_id">Pof</label>
                    <select class="form-select" v-model="newAsset.pof">
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </div>
                </div>
                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <label for="asset_id">Cof</label>
                    <select class="form-select" v-model="newAsset.cof">
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </div>
                </div>
                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <label for="asset_id">{{ $t("fixed_assets.r_f") }}</label>
                    <input
                      v-model="newAsset.r_f"
                      min="0"
                      max="1"
                      step="0.01"
                      type="number"
                      class="form-control mb-2"
                      :placeholder="$t('fixed_assets.r_f')"
                    />
                  </div>
                </div>
                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.risk_score")
                    }}</label>
                    <input
                      v-model="newAsset.risk_score"
                      type="text"
                      class="form-control mb-2"
                      :placeholder="$t('fixed_assets.risk_score')"
                    />
                  </div>
                </div>
                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <label for="asset_id">{{
                      $t("fixed_assets.comment")
                    }}</label>
                    <textarea
                      v-model="newAsset.comment"
                      class="form-control mb-2"
                      :placeholder="$t('fixed_assets.comment')"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="modal-footer"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            >
              <button type="submit" class="btn btn-primary">
                {{ $t("popups.edit") }}
              </button>
              <button
                data-bs-dismiss="modal"
                type="button"
                class="btn btn-danger"
              >
                {{ $t("popups.cancel") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--End Edit Modal-->

    <!-- Dispose Modal -->
    <div
      class="modal fade"
      id="disposeModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="doDispose">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {{
                this.$i18n.locale == "ar" ? "القيمة الحالية" : "Current Value"
                }} {{parseFloat(remaining_value).toLocaleString()}}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style="margin: 0"
              ></button>
            </div>

            <div class="modal-body">
              <div class="row">

                <!-- Dispose Value -->
                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <label for="asset_id">{{ $t("fixed_assets.sell_value") }}</label>
                    <currency-input
                      v-model="disposed_asset.value"
                      class="form-control mb-2"
                    />
                  </div>
                </div>
                <div class="row d-flex align-items-center">
                  <div class="col">
                    <label for="asset_id">{{
                        $t("fixed_assets.choose_account")
                      }}</label>
                  </div>
                  <div class="col d-flex justify-content-end">
                    <button
                        v-if="addAsset.length < 3"
                        type="button"
                        @click="addAsset.push({})"
                        class="btn float-end"
                    >
                        <span
                            class="bx bxs-plus-circle float-end fa-2x"
                            style="color: #2a3042 !important"
                        ></span>
                    </button>
                  </div>
                </div>
                <div v-for="(asset, addAssetIndex) in addAsset" :key="asset">
                  <div class="row my-3">
                    <div class="d-flex justify-content-between">
                      <select
                          v-model="asset.cash_account"
                          class="form-select mb-2"
                          id="asset_id"
                          :placeholder="$t('fixed_assets.accounts')"
                      >
                        <option
                            v-for="cashAccount in cashAccounts"
                            :key="cashAccount"
                            :value="cashAccount"
                        >
                          {{
                            $i18n.locale == "ar"
                                ? cashAccount?.name
                                : cashAccount?.name_en
                          }} - ({{cashAccount.balance.toLocaleString()}})
                        </option>
                      </select>
                      <currency-input
                          v-model="asset.value"
                          class="form-control mb-2 mx-1"
                          :placeholder="$t('emps.amount')"
                      />
                      <span class="mx-2">
                          <button
                              class="btn btn-danger"
                              @click="addAsset.splice(addAssetIndex,1)"
                              v-if="addAsset.length > 1"
                          >
                            <i class="bx bx-trash"></i>
                          </button>
                          <button v-else disabled class="btn btn-danger">
                            <i class="bx bx-trash"></i>
                          </button>
                        </span>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div
              class="modal-footer"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            >
              <button :disabled="disposed_asset.value!=totalDisposalAccountsValue" type="submit" class="btn btn-primary">
                {{ $t("fixed_assets.disposal") }}
              </button>
              <button
                data-bs-dismiss="modal"
                type="button"
                class="btn btn-danger"
              >
                {{ $t("popups.cancel") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>


    <!--   Apps  Pagination     -->
    <div>
      <ul class="pagination pagination-rounded justify-content-center mb-2">
        <li class="page-item" :class="{ disabled: page == 1 }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page - 1)"
            aria-label="Previous"
          >
            <i class="mdi mdi-chevron-left"></i>
          </a>
        </li>
        <li
          class="page-item"
          :class="{ active: p == page }"
          v-for="p in tot_pages"
          :key="p"
        >
          <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
            p
          }}</a>
        </li>

        <li class="page-item" :class="{ disabled: page == tot_pages }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page + 1)"
            aria-label="Next"
          >
            <i class="mdi mdi-chevron-right"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
.purchase-container {
  background: #fafafa;
  margin: 1rem;
  border-radius: 0.5rem;
  padding: 1rem;
}
</style>
