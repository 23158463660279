<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import FixedAssetsPageVue from "./FixedAssetsPage/index.vue";
// import FixedAssetCategories from "./FixedAssetCategories/index.vue";
// import DisposedAssetsPage from "./DisposedAssetsPage/index.vue";
// import FixedAssetClasses from "./FixedAssetClasses/index.vue";
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    FixedAssetsPageVue,
    // FixedAssetCategories,
    // DisposedAssetsPage,
    // FixedAssetClasses,
  },
  data() {
    return {};
  },
  methods: {},
  created() {},
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('fixed_assets.title')"
    />
    <FixedAssetsPageVue />

    <!-- <b-tabs
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      justified
      nav-class="nav-tabs-custom"
      content-class="p-3 text-muted"
    >
      <b-tab active>
        <template v-slot:title>
          <span
            :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            class="d-inline-block d-sm-none"
          >
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block">{{
            $t("fixed_assets.title")
          }}</span>
        </template>
        <FixedAssetsPageVue />
      </b-tab>
      <b-tab>
        <template v-slot:title>
          <span
            :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            class="d-inline-block d-sm-none"
          >
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block">{{
            $t("fixed_assets.disposed")
          }}</span>
        </template>
        <DisposedAssetsPage />
      </b-tab>
      <b-tab>
        <template v-slot:title>
          <span
            :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            class="d-inline-block d-sm-none"
          >
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block">{{
            $t("fixed_assets.categories")
          }}</span>
        </template>
        <FixedAssetCategories />
      </b-tab>
      <b-tab>
        <template v-slot:title>
          <span
            :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            class="d-inline-block d-sm-none"
          >
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block">{{
            $t("fixed_assets.classes")
          }}</span>
        </template>
        <FixedAssetClasses />
      </b-tab>
    </b-tabs> -->
  </Layout>
</template>

<style scoped>
.purchase-container {
  background: #fafafa;
  margin: 1rem;
  border-radius: 0.5rem;
  padding: 1rem;
}
</style>
